/**
 * @generated SignedSource<<4b7cc651f3ea0fe0aaa71e9a4518f1e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "age"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gender"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrusted"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobileNumber"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "age",
        "variableName": "age"
      },
      {
        "kind": "Variable",
        "name": "gender",
        "variableName": "gender"
      },
      {
        "kind": "Variable",
        "name": "isTrusted",
        "variableName": "isTrusted"
      },
      {
        "kind": "Variable",
        "name": "mobileNumber",
        "variableName": "mobileNumber"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "CreateCoPassengerMutation",
    "kind": "LinkedField",
    "name": "createCoPassenger",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CoPassengerNode",
        "kind": "LinkedField",
        "name": "coPassenger",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "age",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mobileNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isTrusted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCoPassengerMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateCoPassengerMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "071fdc486ccc47b2b636fbc62e98bbac",
    "id": null,
    "metadata": {},
    "name": "CreateCoPassengerMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCoPassengerMutation(\n  $age: Int!\n  $gender: GenderEnum!\n  $mobileNumber: String!\n  $name: String!\n  $isTrusted: Boolean\n) {\n  createCoPassenger(age: $age, gender: $gender, mobileNumber: $mobileNumber, name: $name, isTrusted: $isTrusted) {\n    message\n    coPassenger {\n      name\n      age\n      gender\n      mobileNumber\n      id\n      isTrusted\n    }\n  }\n}\n"
  }
};
})();

node.hash = "429e44ea6d7e779652bbe2921e771593";

module.exports = node;
