import React from 'react'
import { Link } from 'react-router-dom'

function MoreOnNuegoCard() {
  return (
    <div className='mainCard-more-on-nuego container'>
        <div className='more-on-nuego-card'>
            <div className='more-on-nuego-sub-card'>
            <div className='data-button-card' >
                <div className='more-on-nuego-text'>
                        We're on a lifelong journey to redefine travel. As India's first intercity electric coach service, we embrace efficiency and cleanliness. But we're not just about buses; we're revolutionizing the entire travel experience.
                </div>
                <button className='more-on-nuego-button'>
                <Link style={{'color':'white'}} to="/about-us" rel="follow">
                
                    More On NueGo
                
                </Link>
                </button>
                
            </div>
            </div>
        </div>
    </div>
  )
}

export default MoreOnNuegoCard