/**
 * @generated SignedSource<<6964d68025bbc6df98fd57a4c7612e85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobileNumber"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "preferredDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "preferredLanguage"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "preferredTime"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestedFrom"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobileNumber",
        "variableName": "mobileNumber"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "preferredDate",
        "variableName": "preferredDate"
      },
      {
        "kind": "Variable",
        "name": "preferredLanguage",
        "variableName": "preferredLanguage"
      },
      {
        "kind": "Variable",
        "name": "preferredTime",
        "variableName": "preferredTime"
      },
      {
        "kind": "Variable",
        "name": "requestedFrom",
        "variableName": "requestedFrom"
      }
    ],
    "concreteType": "CreateCallRequest",
    "kind": "LinkedField",
    "name": "createCallRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CallRequestType",
        "kind": "LinkedField",
        "name": "requestedData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCallRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCallRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "397a12562ecc0d2560e58d341595fe23",
    "id": null,
    "metadata": {},
    "name": "CreateCallRequestMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCallRequestMutation(\n  $mobileNumber: String!\n  $name: String\n  $preferredDate: String!\n  $preferredLanguage: Int!\n  $preferredTime: String!\n  $requestedFrom: Int!\n) {\n  createCallRequest(mobileNumber: $mobileNumber, name: $name, preferredDate: $preferredDate, preferredLanguage: $preferredLanguage, preferredTime: $preferredTime, requestedFrom: $requestedFrom) {\n    message\n    status\n    requestedData {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

node.hash = "6b7059e8e4e916b3f27ece92567058dd";

module.exports = node;
