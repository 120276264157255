import React, { useEffect , useState } from 'react'
import { Link } from 'react-router-dom';
import insuranceNew from "../../../assets/icons/whatsNewInsurance.svg";
import newsCardSmall from "../../../assets/images/News Card Small.png";

const readMore = process.env.REACT_APP_CDN_LINK + `assets/images/read-more-icon.png`
function NewsComponent() {

    const [topnews, setTopNews] = useState([]);

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/latest-news?filters[isTop][$eq]=true&sort=rank:asc&populate=deep,3`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => res.json())
        .then((res) => {
            setTopNews(res.data)
            console.log(res.data)
            })

        .catch((err) => {
            console.log("res",err);
        });
    },[])
  return (

    <div className='container section-news'>
        <div className='d-flex justify-content-between mb-4'>
            <div className='d-flex justify-content-start align-items-center gap-2'>
                <img src={insuranceNew} alt=""  className='icon-48'/>
                <h2 className='ubuntu-700w-32s-48h black-1E1E26-color mb-0'>Top Stories</h2>
            </div>
            {/* <div className='d-flex justify-content-start align-items-center gap-2'>
               <Link to="/newsroom"> <h2 className='ubuntu-700w-18s-28h teal-22BBB0-color mb-0 pointer-cursor' >View All</h2></Link>
                
                <img src={readMore} alt="" />
            </div> */}
        </div>
        {
            topnews.length && <div className='News-grid'>
            <div className='d-flex flex-column gap-3'>
                <div className='d-flex flex-column gap-1'>
                    <img className='rounded-8 image-news-istop'  src={topnews[0]?.attributes?.image} alt="" />
                    <p className='news-heading'>{topnews[0]?.attributes?.title}</p>
                    <div className='d-flex justify-content-start align-items-center gap-2' >
                        <img className='icon-24'src={insuranceNew} alt="" />
                        <p className='open-400w-16s-24h black-1E1E26-color mb-0'>{topnews[0]?.attributes?.source}</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>{topnews[0]?.attributes?.date}</p>
                    </div>
                </div>

                <div className='d-flex flex-column gap-1'>
                    <img className='rounded-8 image-news-istop' src={topnews[1]?.attributes?.image} alt="" />
                    <p className='news-heading'>{topnews[1]?.attributes?.title}</p>
                    <div className='d-flex justify-content-start align-items-center gap-2' >
                        <img className='icon-24'src={insuranceNew} alt="" />
                        <p className='open-400w-16s-24h black-1E1E26-color mb-0'>{topnews[1]?.attributes?.source}</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>{topnews[1]?.attributes?.date}</p>
                    </div>
                </div>
                
            </div>
            <div>
            <div className='d-flex flex-column gap-1'>
                    <img className='rounded-8 middle-news-image' style={{height:'323px'}} src={topnews[2]?.attributes?.image} alt="" />
                    <p className='middle-new-heading'>{topnews[1]?.attributes?.title}</p>
                    <p className='open-400w-16s-24h black-1E1E26-color  text-justify mb-0 middle-news-desc'>{topnews[3]?.attributes?.description}</p>
                    <div className='d-flex justify-content-start align-items-center gap-2' >
                        <img className='icon-24'src={insuranceNew} alt="" />
                        <p className='open-400w-16s-24h black-1E1E26-color mb-0'>{topnews[3]?.attributes?.source}</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>{topnews[3]?.attributes?.date}</p>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column gap-4'>
                <div className='News-Emission'>
                    <div className='Emisssion-data'>
                        <p className='emission-head'>Emissions Prevented</p>
                        <div className='d-flex justify-content-start gap-1'>
                        <ul>
                            <li className="enission-Number">0</li>
                            <li className="enission-Number">6</li>
                            <li className="enission-Number">8</li>
                            <li className="enission-Number">7</li>
                            <li className="enission-Number">5</li>
                            <li className="enission-Number">3</li>
                            <li className="enission-Number">2</li>
                            <li className="enission-Number">3</li>
                            <li className="enission-Number">4</li>
                        </ul>

                        <p className='open-400w-14s-22h white-color mb-0 ml-1'>kgs</p>
                        </div>
                        
                        </div>
                </div>
                <div className='d-flex flex-column gap-3'>
                    <img className='newsPage-image' src={topnews[3]?.attributes?.image} alt="" />
                    <p className='news-heading mb-2'>{topnews[3]?.attributes?.title}</p>
                    <p className='open-400w-16s-24h black-1E1E26-color  text-justify mb-0 middle-news-desc'>{topnews[3]?.attributes?.description}</p>
                    <div className='d-flex justify-content-start align-items-center gap-2' >
                        <img className='icon-24'src={insuranceNew} alt="" />
                        <p className='open-400w-16s-24h black-1E1E26-color mb-0'>{topnews[3]?.attributes?.source}</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>{topnews[3]?.attributes?.date}</p>
                    </div>
                </div>
            </div>
    </div>

        }
        
    </div>
  )
}

export default NewsComponent