/**
 * @generated SignedSource<<7df39e7f232efe6ed837c8b673af1cb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceNo"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowOrder",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Latitude",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Longitude",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PickupDropImage",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "referenceNo",
        "variableName": "referenceNo"
      }
    ],
    "concreteType": "GetPickupDropPointMutation",
    "kind": "LinkedField",
    "name": "getPickupDropPoints",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardPointResponse",
        "kind": "LinkedField",
        "name": "pickupPoints",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "PickupID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "PickupName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "PickupAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "PickupPhone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "PickupTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "LandMark",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DropPointResponse",
        "kind": "LinkedField",
        "name": "dropPoints",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "DropID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "DropName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "DropAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "DropPhone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "DropTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "DropLandMark",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardingAndDroppingMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardingAndDroppingMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "6efd8a0ae2eafaf6b133a7a7ddeec88e",
    "id": null,
    "metadata": {},
    "name": "BoardingAndDroppingMutation",
    "operationKind": "mutation",
    "text": "mutation BoardingAndDroppingMutation(\n  $referenceNo: String!\n) {\n  getPickupDropPoints(referenceNo: $referenceNo) {\n    pickupPoints {\n      id\n      rowOrder\n      Latitude\n      Longitude\n      PickupID\n      PickupName\n      PickupAddress\n      PickupPhone\n      PickupTime\n      LandMark\n      PickupDropImage\n    }\n    dropPoints {\n      id\n      rowOrder\n      Latitude\n      Longitude\n      DropID\n      DropName\n      DropAddress\n      DropPhone\n      DropTime\n      DropLandMark\n      PickupDropImage\n    }\n  }\n}\n"
  }
};
})();

node.hash = "c5fda7fd0cc488ade6b9f06530d35eb7";

module.exports = node;
