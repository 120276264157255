import React from 'react'
import { Link } from 'react-router-dom';
import greenplanet from "../../../assets/images/Save the Earth-pana (1) 2.svg";
import financePlanet from "../../../assets/images/finacereportsvg.svg"

function EnvFinanceCard() {
  return (
    <div className='env-finance-parent-card container '>
      <div className='child-card'>
        <div className='sub-card'>
          <div className='heading-card'>
          <h3 className='teal-heading'>ENVIRONMENT</h3>
          <h4 className='sub-heading'>See how NueGo is contributing towards Green Environment</h4>
          </div>
          <button className='card-cta-button'  >
          <Link style={{'color':'white'}} to="/about-us" rel="follow">Explore Now</Link>
            
          </button>
          

        </div>
        
        <img className='image-env-finance'  src={greenplanet} alt="" />

      </div>
      <div className='child-card'>
        <div className='sub-card'>
          <div className='heading-card'>
          <h3 className='teal-heading'>FINANCIAL REPORTS</h3>
          <h4 className='sub-heading'>NueGo is making electric “The Obvious Choice”</h4>
          </div>
          <button className='card-cta-button'>
          <a style={{'color':'white'}} target='_blank' href="https://greencellmobility.com/static/media/EHS-SR-Policy-Rev-Due.d2bd490aebb48ea87f4f.pdf" rel="nofollow">View Report</a>
          </button>
          

        </div>
        <img className='image-env-finance' src={financePlanet} alt="" />
      </div>
      </div>
  )
}

export default EnvFinanceCard