/**
 * @generated SignedSource<<d294c5ed0e5de8ab86fa962ca5f1a5b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cityId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cityId",
        "variableName": "cityId"
      },
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      }
    ],
    "concreteType": "CityResponseType",
    "kind": "LinkedField",
    "name": "getDestinationsBySource",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "CMCityname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "CMCityid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetDestinationListingQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetDestinationListingQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "83cb2c3a0cd8742ac485c5f415195374",
    "id": null,
    "metadata": {},
    "name": "GetDestinationListingQuery",
    "operationKind": "query",
    "text": "query GetDestinationListingQuery(\n  $cityId: Int!\n  $searchTerm: String\n) {\n  getDestinationsBySource(cityId: $cityId, searchTerm: $searchTerm) {\n    CMCityname\n    CMCityid\n  }\n}\n"
  }
};
})();

node.hash = "bfdbeaffb49c1d4f13621ce632aad965";

module.exports = node;
