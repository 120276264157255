/**
 * @generated SignedSource<<5592f52791e687089f819846e7edf167>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookingId",
        "variableName": "bookingId"
      }
    ],
    "concreteType": "UberHookRedirectView",
    "kind": "LinkedField",
    "name": "uberHookRedirectView",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UberHookRedirectViewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UberHookRedirectViewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "056e50a9287ce4c308733d1597a87b2a",
    "id": null,
    "metadata": {},
    "name": "UberHookRedirectViewMutation",
    "operationKind": "mutation",
    "text": "mutation UberHookRedirectViewMutation(\n  $bookingId: String!\n) {\n  uberHookRedirectView(bookingId: $bookingId) {\n    ok\n    url\n  }\n}\n"
  }
};
})();

node.hash = "0286c89ce9d9b1ecca02888e09423eec";

module.exports = node;
