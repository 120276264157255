/**
 * @generated SignedSource<<67d3438b221e829af08b3b3a0ba375b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingType"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookingType",
        "variableName": "bookingType"
      }
    ],
    "concreteType": "BookingType",
    "kind": "LinkedField",
    "name": "myBooking",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sourceCityName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "destinationCityName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pnrNo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pickupTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dropTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "qrCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateOfJourney",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isPaymentSuccessful",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PassengerDetailsType",
        "kind": "LinkedField",
        "name": "passengers",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "age",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyBookingQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyBookingQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "943ef5e332dcee993bed925233e8ef2d",
    "id": null,
    "metadata": {},
    "name": "MyBookingQuery",
    "operationKind": "query",
    "text": "query MyBookingQuery(\n  $bookingType: MyBookingFilterEnum\n) {\n  myBooking(bookingType: $bookingType) {\n    id\n    sourceCityName\n    destinationCityName\n    pnrNo\n    pickupTime\n    dropTime\n    qrCode\n    dateOfJourney\n    isPaymentSuccessful\n    passengers {\n      id\n      name\n      age\n      gender\n    }\n  }\n}\n"
  }
};
})();

node.hash = "76f61fdd45feb1e134bd81f062befd1e";

module.exports = node;
