import React , {useEffect, useState} from 'react'
import { useMediaQuery } from "react-responsive";
import insuranceNew from "../../../assets/icons/whatsNewInsurance.svg";
import newsCardSmall from "../../../assets/images/News Card Small.png";
const readMore = process.env.REACT_APP_CDN_LINK + `assets/images/read-more-icon.png`

function AnnouncementComponent() {

  const [topAnnouncement, setTopAnnouncement] = useState([]);

  useEffect(()=>{
      fetch(`https://cms.nuego.in/api/latest-announcements?sort=rank:asc&populate=deep,3`,
      {
          headers: {
              'Content-Type': 'application/json',
          },
      })
      .then((res) => res.json())
      .then((res) => {
        setTopAnnouncement(res.data)
          })

      .catch((err) => {
          console.log("res",err);
      });
  },[])

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
});

  return (
    <div className='section-Announcement'>
        <div className='d-flex justify-content-between mb-4'>
            <div className='d-flex justify-content-start align-items-center gap-2'>
                <img src={insuranceNew} alt=""  className='icon-48'/>
                <h2 className='announcement-heading-main'>Announcements</h2>
            </div>
            {/* <div className='d-flex justify-content-start align-items-center gap-2'>
               
                <h2 className='ubuntu-700w-18s-28h teal-22BBB0-color mb-0'>View All</h2>
                <img src={readMore} alt="" />
            </div> */}
        </div>
        <div class="accordion accordion-flush d-flex flex-column gap-3" id="accordionFlushExample">

{
  topAnnouncement.map((item,index)=>{

    return <div class="accordion-item">
    <h2 class="accordion-header" id={`flush-heading${index}`}>
      <button class="accordion-button p-0 rounded collapsed d-flex flex-row gap-2 align-items-center justify-content-between w-100" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
        <div className='d-flex flex-row gap-2 align-items-center' ><img  className='rounded accordion-img' src={item?.attributes?.image} alt="" /> <p className='announcement-heading'>{item?.attributes?.title}</p></div>
        {isDesktopOrLaptop && <div className='d-flex flex-row gap-2 align-items-center' ><img style={{width:"24px", height: '24px'}} className='rounded-circle' src={newsCardSmall} alt="" /> <p className='mb-0'>NueGo</p> . <p className='mb-0'>{item?.attributes?.date}</p></div>}
      </button>
    </h2>
    <div id={`flush-collapse${index}`} class="accordion-collapse collapse" aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">{item?.attributes?.description} {item?.attributes?.link && <a rel="nofollow" href={item?.attributes?.link}> Read more...</a>}</div>
      { !isDesktopOrLaptop && item?.attributes?.link && <a className='ml-3' rel="nofollow" href={item?.attributes?.link}> Read more...</a>}
    </div>
  </div>

  })
} 
        </div>
        
    </div>
  )
}

export default AnnouncementComponent