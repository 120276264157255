/**
 * @generated SignedSource<<87f28a8ecbad3c67eaff27bc8c0798ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CheckListNode",
    "kind": "LinkedField",
    "name": "getChecklist",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isReminder",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAadhaar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isPrescription",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CheckListItemTypeConnection",
        "kind": "LinkedField",
        "name": "checklistitemSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckListItemTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckListItemType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isChecked",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CheckListReminderTypeConnection",
        "kind": "LinkedField",
        "name": "checklistreminderSet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckListReminderTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckListReminderType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reminderDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reminderTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetChecklistQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetChecklistQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "87b21677dedb423842f1fcac06ccd97b",
    "id": null,
    "metadata": {},
    "name": "GetChecklistQuery",
    "operationKind": "query",
    "text": "query GetChecklistQuery {\n  getChecklist {\n    id\n    name\n    isReminder\n    isAadhaar\n    isPrescription\n    checklistitemSet {\n      edges {\n        node {\n          id\n          itemName\n          isChecked\n        }\n      }\n    }\n    checklistreminderSet {\n      edges {\n        node {\n          id\n          reminderDate\n          reminderTime\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "9022a1e6fb01a4f6183d3be206c50ee2";

module.exports = node;
