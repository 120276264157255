import React from 'react'
import { Helmet } from 'react-helmet';
import './News.scss'
import NewsComponent from './components/NewsComponent';
import AnnouncementComponent from './components/AnnouncementComponent';
import MoreOnNuegoCard from './components/MoreOnNuegoCard';
import Navbar from "../../components/Navbar/Navbar";
import {useMediaQuery} from "react-responsive";
import Footer from "../../components/Footer/Footer";
import AuthModalLayout from '../../layout/AuthModalLayout/AuthModalLayout';
import EnvFinanceCard from './components/EnvFinanceCard';
import TwitterComponent from './components/TwitterComponent';
import TwitterContainer from "../../components/TwitterContainer/TwitterContainer";

const News = () => {
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
	

	return (
		<AuthModalLayout>
		<div className={'news-page'}>
		<Helmet>
				<title>NueGo's Bus Travel News | NueGo</title>
				<link rel="canonical" href="https://www.nuego.in/news" hreflang="en-IN" />
				<meta name="description" content="Get bus travel news from NueGo and stay updated with the latest travel trends." />
				<meta name="keywords" content="bus travel news, NueGo travel news "></meta>
				<meta name="robots" content="index,follow"/>
				<script type='application/ld+json'>
					{JSON.stringify({
					"@context": "http://schema.org",
					"@type": "WebPage",
					"url": "https://www.nuego.in/news",
					"name": "NueGo News",
					"description": "Get bus travel news from NueGo and stay updated with the latest travel trends.",
					"publisher": {
						"@type": "Organization",
						"name": "NueGo",
						"logo": {
						"@type": "ImageObject",
						"url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
						"width": 200,
						"height": 100
						}
					}
					})}
				</script>
		</Helmet>
			{/* navbar */}
			{isDesktopOrLaptop && <div style={isDesktopOrLaptop ? {marginBottom: '100px'} : {marginBottom: "77px"}}>
				<Navbar/>
			</div>}
			<div className={'white-fold pt-4 mb-lg-0 '}>
			<div className="d-flex container mt-4">
                        <div className='open-600w-16s-24h grey-text'>Home / </div>
                        
                        <div className='open-700w-16s-24h teal-22BBB0-color'> Newsroom</div>
                        <script type='application/ld+json'>
                            {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                                {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Home",
                                "item": "https://www.nuego.in"
                                },
                                {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "News",
                                "item": "https://www.nuego.in/news"
                                }
                            ]
                            })}
                        </script>
                    </div>
				<NewsComponent/>
				<div style={{marginTop:'36px', marginBottom:"36px"}}>
				<AnnouncementComponent/>
				</div>
				<MoreOnNuegoCard/>
				{/* <TwitterComponent/> */}
				<EnvFinanceCard/>
				
			</div>
			{/* {isDesktopOrLaptop && <Footer/>} */}
			<Footer/>
		</div>
		</AuthModalLayout>
	)
}

export default News