import React from 'react'
import {useMediaQuery} from "react-responsive";
import {useNavigate} from "react-router";

//assets
const successIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/success-illustration.png`
const failureIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/failure-illustration.png`

const RefundResultComponent = (props) => {
    const {success, message} = props
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const navigate = useNavigate();
    let pageConstant
    if (isDesktopOrLaptop) {
        pageConstant = {

            successTitle_class: "ubuntu-500w-20s-32h mb-2",
            successDescription_class: "open-400w-14s-22h"
        }
    } else {
        pageConstant = {

            successTitle_class: "ubuntu-500w-20s-32h mb-2",
            successDescription_class: "open-400w-14s-22h"
        }
    }

    return (
        <div className={'refund-success'}>
            <div className={'text-center'}>
                <img src={success ? successIllustration : failureIllustration} alt={'alt'}
                     className={'success-illustration'}/>
                {
                    success ?
                        <p className={pageConstant.successTitle_class}>Refund Successfull</p>
                        :
                        <p className={pageConstant.successTitle_class}>Sorry! You can’t cancel
                            this booking</p>
                }

                <p className={pageConstant.successDescription_class}>{message}</p>
            </div>
            <button className={'submit-button'} onClick={() => navigate('/', {replace: false})}>
                Go To Home
            </button>
        </div>
    )
}

export default RefundResultComponent
