/**
 * @generated SignedSource<<8e2d4afeb2b0ce389dfcca16c5a3086e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "DeleteCheckListItemsMutation",
    "kind": "LinkedField",
    "name": "deleteCheckListItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteChecklistItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteChecklistItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3af6c0fe07f98d1ff5b500cb83da7e2b",
    "id": null,
    "metadata": {},
    "name": "DeleteChecklistItemMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteChecklistItemMutation(\n  $ids: [ID]!\n) {\n  deleteCheckListItems(ids: $ids) {\n    status\n    message\n  }\n}\n"
  }
};
})();

node.hash = "914f8f8fae8cbf69b985b13f84bbbca1";

module.exports = node;
