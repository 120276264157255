import React, { useEffect, useState } from "react";
import { setAuthType } from "../../store/Actions/AuthActions";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import { useAuthContext } from "../../hooks/context/auth-context";
// import OtpInput from "../SingleOTPInput/SingleOtpInput";
import TurnstileWidget from "../TurnstileWidget/TurnstileWidget";
//icons
import backArrow from "../../assets/icons/back-black-arrow.svg";
import { ResendOtp, VerifyOtp } from "../../services/api/DataUpdating";
import { useMediaQuery } from "react-responsive";
import { secondsToTime } from "../../utils/utils";

const OtpVerificationDesktop = (props) => {
	const { setTypeToStore, mobileNumberFromStore } = props;

	const {
		setOtpValue,
		otpValue,
		timer,
		setTimer,
		feedbackRemindersFetch,
	} = useAuthContext();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
	const [otpValidation, setOtpValidation] = useState("");
	const [hideResendOtp, setHideResendOtp] = useState(true);
	const [showCaptcha, setShowCaptcha] = useState(false);
	
	const [seconds, setSeconds] = useState(300);
    // let seconds = 300

	
	useEffect(() => {
		if (seconds > 0) {
			let intervalId = setInterval(() => {
				setSeconds(seconds - 1);
				// seconds = seconds - 1;
			}, 1000);
			

			return () => clearInterval(intervalId);
		} else {
			setHideResendOtp(false);
		}
	}, [seconds]);

    useEffect(() => {
        setTimeout(() => {
            setHideResendOtp(false);
        }, 10000 * 6);

    }, [])
	const onResendOtp = (captchaToken) => {
		if (!hideResendOtp && captchaToken) {
			let variables = {
				mobileNumber: mobileNumberFromStore,
				captchaToken: captchaToken,
			};
			let count = localStorage.getItem("resendOtpState");
			ResendOtp(
				variables,
				() => {
					localStorage.setItem(
						"resendOtpState",
						count ? parseInt(count) + 1 : 1,
					);
					
					setHideResendOtp(true);
					setShowCaptcha(false);
					// seconds = 300;
					setSeconds(300);
                    setTimeout(() => {
                        setHideResendOtp(false);
                    }, 10000 * 6);
				},
				() => {
					setShowCaptcha(false);
				},
			);
		}
	};

	const verifyOtpOperation = () => {
		let variables = {
			mobileNumber: mobileNumberFromStore,
			otp: otpValue,
		};
		VerifyOtp(variables, isDesktopOrLaptop, () => {
			feedbackRemindersFetch && feedbackRemindersFetch();
			setOtpValidation("success");
		});
	};

	return (
		<div className={"w-50  m-auto py-5"}>
			<img
				src={backArrow}
				className={"mb-4"}
				onClick={() => setTypeToStore("d-signin")}
				alt={"alt"}
			/>
			<p className={"ubuntu-700w-18s-28h mb-2"}>Enter Verification code</p>
			<div className={"d-flex margin-bottom-32"}>
				<p className={"open-600w-14s-22h opacity-60 mb-2"}>OTP sent to </p>
				<p className={"open-600w-14s-22h mb-2 ml-2"}>{mobileNumberFromStore}</p>
			</div>
			<p className={"open-600w-14s-22h opacity-60"}>Enter OTP</p>
			<div className={"w-fit-content margin-bottom-32 otp-input-wrapper"}>
				<OtpInput
					inputType="number"
					value={otpValue}
					onChange={setOtpValue}
					numInputs={4}
					inputStyle={
						otpValidation === "success"
							? "otp-input-style-active"
							: otpValidation === "error"
							? "otp-input-style-error"
							: "otp-input-style"
					}
					renderSeparator={<span></span>}
					renderInput={(props) => <input {...props} />}
				/>
			</div>

			<button
				className={
					"teal-22BBB0-bg white-color open-600w-16s-24h p-3 w-100 submit-button mb-3"
				}
				onClick={verifyOtpOperation}
			>
				Submit
			</button>

			<div className={"d-flex align-items-center justify-content-between"}>
				<p className={"open-400w-14s-22h opacity-60 mb-0"}>
					Get code <span className={"open-700w-14s-22h "}>VIA CALL</span>
				</p>

				<div
					className={
						"d-flex justify-content-between  align-items-center " +
						(hideResendOtp ? "disable-resend-otp" : "")
					}
				>
					<div className={"d-flex align-items-center"}>
						{/*<img src={clock} alt={'alt'} className={'icon-24'}/>*/}
						<p className={"open-600w-14s-22h opacity-60 mb-0"}>
                        {secondsToTime(seconds).m}:{secondsToTime(seconds).s}
						</p>
					</div>
					<p
						className={
							"open-600w-14s-22h teal-22BBB0-color cursor-pointer mb-0 ml-3 " +
							(hideResendOtp && " disable-resend-otp")
						}
						onClick={() => setShowCaptcha(true)}
					>
						RESEND OTP
					</p>
				</div>
			</div>
			{showCaptcha && (
				<TurnstileWidget
					onSuccess={(token) => onResendOtp(token)}
					forceCaptcha={localStorage.getItem("resendOtpState") > 2}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	mobileNumberFromStore: state.AuthReducer.mobileNumber,
});
const mapDispatchToProps = (dispatch) => ({
	setTypeToStore: (data) => dispatch(setAuthType(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(OtpVerificationDesktop);
