/**
 * @generated SignedSource<<b9263738de79a3cecdfb1a463d4c1172>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      }
    ],
    "concreteType": "CityResponseType",
    "kind": "LinkedField",
    "name": "source",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "CMCityname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "CMCityid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetSourceListingQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetSourceListingQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9233ab73535427c9f35a552f803db5ce",
    "id": null,
    "metadata": {},
    "name": "GetSourceListingQuery",
    "operationKind": "query",
    "text": "query GetSourceListingQuery(\n  $searchTerm: String\n) {\n  source(searchTerm: $searchTerm) {\n    CMCityname\n    CMCityid\n  }\n}\n"
  }
};
})();

node.hash = "beb77eba4cac6303c71cb54999d16103";

module.exports = node;
