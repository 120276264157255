import React, { useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet';
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom"
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import cogoToast from "cogo-toast";
import Loader from "../../components/Loader/Loader";
import { CustomToast } from "../../components/CustomToast/CustomToast"
// icons
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import "./Blog.scss"

function Blog() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();


    return (
        <div className={"blog-Page"}>
            <Helmet>
                <title>Travel Blogs in India | NueGo</title>
                <link rel="canonical" href="https://www.nuego.in/blog" hreflang="en-IN" />
                <meta name="description" content="NueGo's travel and tourism blogs suggests best tourist attractions and offers advices for better travel experiences. Discover blogs for solo adventure or group getaways." />
                <meta name="keywords" content="travel blogs,travel blog site,tourism blog,travel blog post,short travel blog"></meta>
                <meta name="robots" content="index,follow" />
                <script type='application/ld+json'>
                {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "WebPage",
                "url": "https://www.nuego.in/blog",
                "name": "NueGo Blog",
                "description": "NueGo's travel and tourism blogs suggests best tourist attractions and offers advices for better travel experiences. Discover blogs for solo adventure or group getaways.",
                "publisher": {
                    "@type": "Organization",
                    "name": "NueGo",
                    "logo": {
                    "@type": "ImageObject",
                    "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                    "width": 200,
                    "height": 100
                    }
                }
                })}
                </script>
            </Helmet>
            {

                isDesktopOrLaptop ? <AuthModalLayout>
                    <Navbar />
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo Blog"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                    </MobileSubScreenLayout>
            }
        </div>
    );
};

export const MainContent = () => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const ref = useRef();


    const data = [
        {
            tittle:"Don't Skip Out on These Incredible South Indian Tourist Places to Visit",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Don't%20Skip%20Out%20on%20These%20Incredible%20South%20Indian%20Tourist%20Places%20to%20Visit.jpg",
            imgAlt:"Best Places to Visit in South India",
            desc: "In this beautiful land known for so many beautiful things, it is no surprise that the beauty of South India is something that cannot be expressed in mere words, let alone using blog posts to describe these places. However, if you want to know which place to visit, what to do in South India, and how to make the best of your trip by visiting theSouth India tourist placesthat will blow your mind, here we are with a list for exactly that.",
            route: "/blog/best-south-indian-tourist-places-to-visit",
        },
        {
            tittle:"10 Things To Do in Pondicherry in 2024 That You Won't Find in Guidebooks",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Things%20To%20Do%20in%20Pondicherry%20in%202024%20That%20You%20Won't%20Find%20in%20Guidebooks.jpg",
            imgAlt:"Best Places To Visit This Monsoon In India",
            desc: "If India were an ice cream, Pondicherry would be the cherry on top. Not just because the name has “cherry” in it, but also because despite how small Pondicherry is, it will always pack a punch that leaves every tourist spellbound after visiting this very interesting treasure trove of cultural diversity.",
            route: "/blog/things-to-do-in-pondicherry",
        },
        {
            tittle:"20 Places To Visit in India During Monsoon",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/20_Mansoon_images/mansoon_hero_image.png",
            imgAlt:"Best Places To Visit This Monsoon In India",
            desc: "All umbrellas are anxious during the monsoon, because they are under a lot of pressure to perform. But for humans, the monsoon is a boon. The sheer amount of joy you would get from dancing in the rain, eating pakoras in a cozy room as it rains, or even seeing the onset of rain with dark clouds changing the brightness of your room is nothing short of legendary.",
            route: "/blog/monsoon-tourist-places-in-india",
        },
        {
            tittle:"Weekend Getaways from Bangalore: Short Getaway Places Near Bangalore in 2024",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Banglore.jpg",
            imgAlt:"Best Places To Visit This Monsoon In India",
            desc: "Bangalore is a beautiful place and living there is a privilege despite what anyone will tell you about a bustling city being overcrowded. Bangalore has facilities that not many other places in India can boast of. However, we wouldn’t hold it against you if you wanted to step out of Bangalore on the weekend.",
            route: "/blog/weekend-getaways-from-bangalore",
        },
        {
            tittle:"15 Famous Places to Visit in Vijayawada in 2024 for Sightseeing and Tourist Spots",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15%20Famous%20Places%20to%20Visit%20in%20Vijayawada%20in%202024%20for%20Sightseeing%20and%20Tourist%20Spots.jpg",
            imgAlt:"Best Places To Visit This Monsoon In India",
            desc: "Vijayawada, known as the 'City of Victory', celebrates the triumph of Goddess Durga over Mahishasura, offering a cultural tapestry of festivals and temples steeped in mythological significance. Explore its vibrant heritage through majestic temples and annual festivities honoring the divine intervention in ancient legends.",
            route: "/blog/famous-places-to-visit-in-vijayawada",
        },
        
        {
            tittle:"15 Best Sightseeing Places to Visit in Haridwar",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Haridwar_15%20Best%20Sightseeing.jpg",
            imgAlt:"Har Ki Pauri: One of the most popular attractions in Haridwar.",
            desc: "Haridwar is cool. Both literally and figuratively. But the pleasant weather and hilly bliss aside, Haridwar is a lot cooler for what it has - a plethora of charming pilgrimage sites awaiting its devotees every year. Theplaces to visit in Haridwarare countless, and the temples and ashrams in Haridwar are all a spectacular kaleidoscope of devotion and grace, waiting to be experienced by a passionate tourist such as yourself.",
            route: "/blog/sightseeing-places-to-visit-in-haridwar",
        },
        {
            tittle:"Best Places to Visit for One-Day Trips from Bangalore",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/One-Day%20Trips%20from%20Bangalore.jpg",
            imgAlt:"one day tour from Bangalore by Nuego",
            desc: "Bangalore! The galore for all things fun. The weather is perpetually pleasant, there is an entire city with facilities second to very few, and countryside and villages are awaiting you just outside the city if you are tired of honks and noises. Bangalore is one of India's most developed cities while maintaining a fair balance of natural habitat surrounding nearby rural areas.",
            route: "/blog/one-day-trip-from-bangalore",
        },
        {
            tittle:"10 Famous Tourist Places and Attractions in Agra You Cannot Miss",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20in%20Agra.jpg",
            imgAlt:"Taj Mahal: One of the Famous Places to Visit in Agra",
            desc: "Agra! There’s something about that place, a serene and everlasting charm filled with love and romance. Thefamous places in Agra, all exhibit beauty that even pages from history cannot narrate with words.",
            route: "/blog/famous-tourist-places-to-visit-in-agra",
        },
        {
            tittle:"Your Complete Guide to Places to Visit in Ujjain in 2024",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Your%20Complete%20Guide%20to%20Places%20to%20Visit%20in%20Ujjain%20in%202024.jpg",
            imgAlt:"Best Places To Visit This Monsoon In India",
            desc: "Immense is the attraction of power, and many people spend their lifetimes trying to attain it. Yet, one city has always tested the powerful, and even ripped it off of them, should they be unworthy. We speak, of course, of the ancient cityAvantika, which we all call Ujjain today. One of the four locations of the Kumbh Mela, held every 12 years, the new name Ujjain may have changed how people see the city, but testing the powerful remains an occurrence even to this day.",
            route: "/blog/places-to-visit-in-ujjain",
        },
      
        {
            tittle:"10 Places to Visit in India in August To Enjoy Rainy Weather 2024",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/placestovisitAugust.png",
            imgAlt:"Best Places To Visit This Summer Vacation In India",
            desc: "A gust of wind, August of winds. No doubt, as monsoon ensures a windy August in India every year, a gust of wind is very likely to be encountered. The best places to visit in August are likely to give you at least some satisfaction of having rain danced.",
            route: "/blog/best-places-to-visit-in-august",
        },
        {
            tittle:"25 Best Places To Visit This Summer Vacation In India",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/summerBlogImage.png",
            imgAlt:"Best Places To Visit This Summer Vacation In India",
            desc: "We’re set to take you on the perfect getaway this summer. India offers a treasure trove of destinations that promise vibrant experiences and relief from the sweltering heat. From breezy hill stations in the north to the serene beaches down south, there's no shortage of memorable places to visit this summer.",
            route: "/blog/places-to-visit-in-summer",
        },
        {
            tittle:"Complete Guide To Celebrate Holi In Mathura-Vrindavan",
            img: "https://nuego1.blr1.cdn.digitaloceanspaces.com/web2/blog/holi-in-mathura-vrindavan.jpg",
            imgAlt:"Guide To Celebrate Holi In Mathura-Vrindavan | NueGo",
            desc: "Have you ever wondered where colours blend with culture, creating a spectacle unlike any other? Welcome to the vibrant celebration of Holi in Mathura-Vrindavan, where traditions paint the town in hues of joy and unity.",
            route: "/blog/guide-to-celebrate-holi-in-mathura-vrindavan",
        },
        {
            tittle:"March Long Weekend 2024: 7 Places You Must Visit",
            img: "https://nuego1.blr1.cdn.digitaloceanspaces.com/web2/blog/marchlongweekend2024.jpg",
            imgAlt:"March Long Weekend 2024: 7 Places You Must Visit | NueGo",
            desc: "March is here, and with it, the promise of March long weekend 2024. Wondering how to make the most of these golden opportunities? Whether you're a working professional craving a break or simply eager for a getaway, we've got you covered.",
            route: "/blog/march-long-weekend-trip-ideas",
        },
        {
            tittle:"Top 20 Solo Travel Destinations For Women | NueGo",
            img: "https://nuego-cms.blr1.cdn.digitaloceanspaces.com/whatsapp-creatives/soloFemaleTravellers.png",
            imgAlt:"Best Places to Visit with Your Friends",
            desc: "Are you craving a solo adventure, but hesitant to navigate the world alone? Solo travel for women offers unparalleled independence and self-discovery, yet safety considerations can emerge. Here, we ease those anxieties. This curated list unveils the best travel destinations for solo female travellers.",
            route: "/blog/solo-travel-destinations-for-women",
        },
        {
            tittle:"Friendship Day Getaways: 30 Best Places to Visit with Your Friends",
            img: "https://nuego-cms.blr1.cdn.digitaloceanspaces.com/whatsapp-creatives/BestPlacesToVisitPlaces.png",
            imgAlt:"Best Places to Visit with Your Friends",
            desc: "Ever feel like life's daily grind is putting a damper on your friendships? We've all been there. But what if there was a cure - a chance to reconnect and create memories that'll last a lifetime? Yes, we’re hinting towards an epic trip with your best buds!",
            route: "/blog/travel-places-to-visit-with-friends",
        }
    ]

    

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }



    return (
        <>
            
            {loading ? <Loader /> :
                <div className={"main-content"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Blog</p>
                        <script type='application/ld+json'>
                            {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                                {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Home",
                                "item": "https://www.nuego.in"
                                },
                                {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Blogs",
                                "item": "https://www.nuego.in/blog"
                                }
                            ]
                            })}
                        </script>
                    </div>
                    }
                    <div className="container d-flex flex-row">

                        {
                            data.map((item, indx)=>{
                                return <div key={indx} className="card text-center">
                                <div className={`card-header`}>
                                    {item.tittle}
                                </div>
                                <div className="card-body">
                                    <img className="card-img-top" src={item.img} alt={item.imgAlt}/>
                                    {/* <h5 className"card-title">Special title treatment</h5> */}
                                    <p className={`card-text`}>{item.desc}</p>
                                    <Link to={item.route} rel="follow" className="btn">Read More</Link>
                                </div>
                            </div>
                            })
                        }
                        
                    </div>

                </div>
            }
            <Footer />
        </>

    );
}

export default Blog