/**
 * @generated SignedSource<<61a35dec75195c51a59ee621b3f063c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checkListId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checkListReminder"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isAadhaar"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPrescription"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "checkListId",
        "variableName": "checkListId"
      },
      {
        "kind": "Variable",
        "name": "checkListReminder",
        "variableName": "checkListReminder"
      },
      {
        "kind": "Variable",
        "name": "isAadhaar",
        "variableName": "isAadhaar"
      },
      {
        "kind": "Variable",
        "name": "isPrescription",
        "variableName": "isPrescription"
      }
    ],
    "concreteType": "CreateCheckListItemAndReminderMutation",
    "kind": "LinkedField",
    "name": "createCheckListItemAndReminder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CheckListNode",
        "kind": "LinkedField",
        "name": "checkList",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isReminder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAadhaar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPrescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckListItemTypeConnection",
            "kind": "LinkedField",
            "name": "checklistitemSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckListItemTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CheckListItemType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "itemName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isChecked",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CheckListReminderTypeConnection",
            "kind": "LinkedField",
            "name": "checklistreminderSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CheckListReminderTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CheckListReminderType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reminderDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reminderTime",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNewChecklistReminderMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddNewChecklistReminderMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "5ce959625ff1e7dc5fd9b1e091f4bb11",
    "id": null,
    "metadata": {},
    "name": "AddNewChecklistReminderMutation",
    "operationKind": "mutation",
    "text": "mutation AddNewChecklistReminderMutation(\n  $checkListId: String!\n  $isAadhaar: Boolean!\n  $isPrescription: Boolean!\n  $checkListReminder: [CheckListReminderInput]!\n) {\n  createCheckListItemAndReminder(checkListId: $checkListId, isAadhaar: $isAadhaar, isPrescription: $isPrescription, checkListReminder: $checkListReminder) {\n    status\n    message\n    checkList {\n      id\n      name\n      isReminder\n      isAadhaar\n      isPrescription\n      checklistitemSet {\n        edges {\n          node {\n            id\n            itemName\n            isChecked\n          }\n        }\n      }\n      checklistreminderSet {\n        edges {\n          node {\n            id\n            reminderDate\n            reminderTime\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "a2aab3ac35185f7c1f7c83758e21321a";

module.exports = node;
