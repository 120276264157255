import graphql from 'babel-plugin-relay/macro';

const GetTransactionDetailsQuery = graphql`
	
	query GetTransactionDetailsQuery($limit:Int,$walletType:WalletTypeEnum) {
	    getTransactions(limit:$limit,walletType:$walletType) {
	        TransDate
	        Debit
	        Credit
	        DebitCreditStatus
	        ClosingBalance
	        NonPromotionalBalance
			Details
	    }
	}
	`

export default GetTransactionDetailsQuery;