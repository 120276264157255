/**
 * @generated SignedSource<<0269cd45bb23cbfcaa738f7c474c161f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cancellationReason"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cancellationRemark"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paymentType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "refundType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookingId",
        "variableName": "bookingId"
      },
      {
        "kind": "Variable",
        "name": "cancellationReason",
        "variableName": "cancellationReason"
      },
      {
        "kind": "Variable",
        "name": "cancellationRemark",
        "variableName": "cancellationRemark"
      },
      {
        "kind": "Variable",
        "name": "paymentType",
        "variableName": "paymentType"
      },
      {
        "kind": "Variable",
        "name": "refundType",
        "variableName": "refundType"
      }
    ],
    "concreteType": "CancelBooking",
    "kind": "LinkedField",
    "name": "cancelBooking",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CancelBookingType",
        "kind": "LinkedField",
        "name": "cancelBooking",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalFare",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refundAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "seatNumbers",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelBookingSinglePassengerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelBookingSinglePassengerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7247f7f1881daf54e4e0912c935e0f7f",
    "id": null,
    "metadata": {},
    "name": "CancelBookingSinglePassengerMutation",
    "operationKind": "mutation",
    "text": "mutation CancelBookingSinglePassengerMutation(\n  $bookingId: String!\n  $cancellationReason: String!\n  $cancellationRemark: String\n  $paymentType: PaymentTypeEnum\n  $refundType: Int!\n) {\n  cancelBooking(bookingId: $bookingId, cancellationReason: $cancellationReason, cancellationRemark: $cancellationRemark, paymentType: $paymentType, refundType: $refundType) {\n    cancelBooking {\n      totalFare\n      status\n      refundAmount\n      seatNumbers\n    }\n  }\n}\n"
  }
};
})();

node.hash = "e4950d29e54122013cbd7bf629ed2a1d";

module.exports = node;
