/**
 * @generated SignedSource<<3ed2edbf28026f4ea7b90448ce091a50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isInsuranceApplied"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "bookingId",
    "variableName": "bookingId"
  },
  {
    "kind": "Variable",
    "name": "isInsuranceApplied",
    "variableName": "isInsuranceApplied"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPayable",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "getTotalPaidAmount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApplyInsuranceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplyInsurance",
        "kind": "LinkedField",
        "name": "applyInsurance",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingType",
            "kind": "LinkedField",
            "name": "booking",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApplyInsuranceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplyInsurance",
        "kind": "LinkedField",
        "name": "applyInsurance",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingType",
            "kind": "LinkedField",
            "name": "booking",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "238ae3c6e0c684c86cf67d7887156197",
    "id": null,
    "metadata": {},
    "name": "ApplyInsuranceMutation",
    "operationKind": "mutation",
    "text": "mutation ApplyInsuranceMutation(\n  $bookingId: ID!\n  $isInsuranceApplied: Boolean!\n) {\n  applyInsurance(bookingId: $bookingId, isInsuranceApplied: $isInsuranceApplied) {\n    status\n    message\n    booking {\n      totalPayable\n      getTotalPaidAmount\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "97ecca3f6e83c0c895b926615080422a";

module.exports = node;
