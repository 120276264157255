/**
 * @generated SignedSource<<6233ba8e17dcabd39fa70eb37051506d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookingId",
        "variableName": "bookingId"
      }
    ],
    "kind": "ScalarField",
    "name": "sendTicketToSms",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendTicketToSmsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendTicketToSmsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ecba2086784912b09ef749db725a9736",
    "id": null,
    "metadata": {},
    "name": "SendTicketToSmsQuery",
    "operationKind": "query",
    "text": "query SendTicketToSmsQuery(\n  $bookingId: String!\n) {\n  sendTicketToSms(bookingId: $bookingId)\n}\n"
  }
};
})();

node.hash = "b44418cfea6ea64c8a9485ad359a1e91";

module.exports = node;
