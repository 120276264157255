/**
 * @generated SignedSource<<b12582715afce17378139285eb5ccfb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paymentMode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookingId",
        "variableName": "bookingId"
      },
      {
        "kind": "Variable",
        "name": "paymentMode",
        "variableName": "paymentMode"
      }
    ],
    "concreteType": "CompleteRescheduleBooking",
    "kind": "LinkedField",
    "name": "completeRescheduleBooking",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaymentType",
        "kind": "LinkedField",
        "name": "payment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingType",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemOrderId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jsonData",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isRescheduleCompleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookingRescheduleCompleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookingRescheduleCompleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f0f0bff437e39ded205af4d694b3c3ce",
    "id": null,
    "metadata": {},
    "name": "BookingRescheduleCompleteMutation",
    "operationKind": "mutation",
    "text": "mutation BookingRescheduleCompleteMutation(\n  $bookingId: ID!\n  $paymentMode: Int!\n) {\n  completeRescheduleBooking(bookingId: $bookingId, paymentMode: $paymentMode) {\n    payment {\n      order {\n        id\n      }\n      itemOrderId\n      paymentId\n      jsonData\n      paymentUrl\n    }\n    isRescheduleCompleted\n  }\n}\n"
  }
};
})();

node.hash = "a1a21778612993b719f0ff0e464e8a1f";

module.exports = node;
