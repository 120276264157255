import React, {Suspense, useEffect, useState} from "react";
import {Route, Routes , useLocation} from "react-router-dom";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import CouponList from "./pages/CouponList/CouponList";
import ChangeBookingPage from "./pages/ChangeBookingPage/ChangeBookingPage";
import RescheduleConfirmation from "./pages/Confirmation/RescheduleConfirmation";
import OfferListPageDesktop from "./pages/OfferListPageDesktop/OfferListPageDesktop";
import PrivacyPolicy from "./pages/PrivacyPolicy/PriacyPolicy";
import Support from "./pages/Support/Support";
import Faq from "./pages/FAQ/FAQ";
import EnvironmentPage from "./pages/EnvironmentPage/Environments";
import QrPage from "./pages/QrPage/QrPage";
import ScrollToTop from "./utils/utils";
import AllBusDestination from "./pages/AllBusDestination/AllBusDestination";
import ProfileDetail from "./pages/ProfileDetail/ProfileDetail";
import Lounge from "./pages/Lounge/Lounge";
import LuckyDraw from "./pages/LuckydrawPage/LuckyDraw";
import {useNavigate} from "react-router";
import {getVersionData} from "./services/api/DataFetching";
import {store} from "./index";
import {setVersionAction} from "./store/Actions/CommonAction";
import AddMoneyResultPage from "./pages/AddMoneyResultPage/AddMoneyResultPage";
import TicketDetailPage from "./pages/TicketDetailPage/TicketDetailPage";
import Careers from "./pages/Careers/Careers";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import News from "./pages/Newsroompage/News";
import PartnerWithUs from "./pages/PartnerWithUs/PartnerWithUs";
import CompletedTrip from "./pages/CompletedTrip/CompletedTrip";
import CoPassengerDetailPage from "./pages/CoPassengerDetailPage/CoPassengerDetailPage";
import ChecklistPageMobile from "./pages/ChecklistPageMobile/ChecklistPageMobile";
import BookingState from "./pages/BookingStates/BookingState";
import OfferAvailable from "./pages/OfferAvailable/OfferAvailable";
import ChangeBookingSection from "./pages/ChangeBooking/ChangeBooking";
import FAQDetails from "./pages/FAQDetailsPage/FADetails";
import DownloadApp from "./pages/DownloadApp/DownloadApp";
import ChecklistDetailPageMobile from "./pages/ChecklistPageMobile/ChecklistDetailPageMobile";
//import MobileFAQForm from "./pages/FAQ/MobileFAQForm";
import DesktopFAQForm from "./pages/FAQ/DesktopFaqForm";

import MobileOffer from "./pages/OffferInMobilDevice/mobileOffer";
import PaymentDeductedSeatNotBooked from "./pages/PaymentDeductedSeatNotBooked/PaymentDeductedSeatNotBooked";
import GuestUserBookingMobile from "./pages/GuestuserBookingMobile/GuestUserBookingMobile";
import {useMediaQuery} from "react-responsive";
import ScrollArrow from "../src/assets/icons/arrow-scroll-up.svg"
import TermConditions from "./pages/TermConditions/TermConditions";
import cogoToast from "cogo-toast";
import {CustomToast} from "./components/CustomToast/CustomToast";
import ReviewPaymentContextProvider from "./hooks/context/review-payment-context";
import FnBConfirmation from "./pages/F&B/F&BConfirmation";
import PaymentInProcess from "./pages/PaymentInProcess/PaymentInProcess";
import Blog from "./pages/Blog/Blog"
import HoliCelebrrationMathura from "./pages/Blog/SpecificBlogs/HoliCelebrrationMathura";
import MarchLongWeekend from "./pages/Blog/SpecificBlogs/MarchLongWeekend";
import SoloFemaleTravellers from "./pages/Blog/SpecificBlogs/SoloFemaleTravellers";
import BestPlaceToVisitWithFriends from "./pages/Blog/SpecificBlogs/BestPlaceToVisitWithFriends";
import BestPlacesToVisitSummer from "./pages/Blog/SpecificBlogs/BestPlacesToVisitSummer"
import Sitemap from "./pages/Sitemap/Sitemap";
import BestPlacesTotravelInAugust from "./pages/Blog/SpecificBlogs/BestPlacesTotravelInAugust";
import NoRoute404 from "./pages/NoRoute404/NoRoute404";
import TwentyPlacestoVisitinIndiaDuringMonsoon from "./pages/Blog/SpecificBlogs/TwentyPlacestoVisitinIndiaDuringMonsoon";
// import AllnewsComponent from "./pages/Newsroompage/components/AllnewsComponent";
import Best11PlaceinSouthIndia from "./pages/Blog/SpecificBlogs/Best11PlaceinSouthIndia";
import Best10placesinPondicherry from "./pages/Blog/SpecificBlogs/Best10PlacesinPondicherry";
import Best15PlacestoVisitInHaridwar from "./pages/Blog/SpecificBlogs/Best15PlacestoVisitInHaridwar";
import BestPlacesTotravelfor1dayfromBlr from "./pages/Blog/SpecificBlogs/BestPlacesTotravelfor1dayfromblr";
import Best10TouristPlacesInAgra from "./pages/Blog/SpecificBlogs/Best10TouristPlacesInAgra";
import Guide4Ujjain from "./pages/Blog/SpecificBlogs/Guide4Ujjain";
import WeekendendGetAwaysfromBlr from "./pages/Blog/SpecificBlogs/WeekendendGetAwaysfromBlr";
import Best15PlacestoTravelFromVijayWada from "./pages/Blog/SpecificBlogs/Best15PlacestoTravelFromVijayWada";

const LoadingAnimation = process.env.REACT_APP_CDN_LINK + "assets/images/Loading_Amination-white.gif"

const Home = React.lazy(() => import("./pages/Home/Home"));
const Bookings = React.lazy(() => import("./pages/Bookings/Bookings"));
const Booking2 = React.lazy(() => import("./pages/Bookings/Booking2"))
const SeatArrangementMobile = React.lazy(() =>
	import("./pages/SeatArrangementMobile/SeatArrangementMobile")
);
const BoardingAndDroppingMobile = React.lazy(() =>
	import("./pages/BoardingAndDroppingMobile/BoardingAndDroppingMobile")
);
const AllRoutes = React.lazy(() =>
    import("./pages/AllBusDestinationNew/AllBusDestinationNew")
);
const CoachDetailsNew = React.lazy(() => import("./pages/CoachDetailsNew/CoachDetailsNew"))
const ReviewAndPayment = React.lazy(() =>
    import("./pages/ReviewBookingAndPayment/ReviewAndPayment/ReviewAndPayment")
);
const PaymentMobile = React.lazy(() =>
    import("./pages/ReviewBookingAndPayment/PaymentMobile/PaymentMobile")
);
const Confirmation = React.lazy(() =>
    import("./pages/Confirmation/Confirmation")
);
const PaymentFailure = React.lazy(() =>
    import("./pages/PaymentFailure/PaymentFailure")
);
const MyBookings = React.lazy(() => import("./pages/MyBookings/MyBookings"));
const Wallet = React.lazy(() => import("./pages/Wallet/Wallet"));
const NuegoSafetyMobile = React.lazy(() => import("./pages/NuegoSafetyMobileDetailPage/NuegoSafetyMobileDetailPage"));
const Magazine = React.lazy(()=>import("./pages/Magazine/MagzineHome"))
const Itinerary = React.lazy(()=>import("./pages/Itinerary/Itinerary"))
const Fnb = React.lazy(()=>import("./pages/F&B/F&B"))

const CombinedRoutes = () => {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const [installPromptEvent, setInstallPromptEvent] = useState(null);
    let pathname = useLocation().pathname

    const scrollToTopArrow = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    }

    useEffect(() => {
        getVersionData(
            (version) => {
                setTimeout(() => {
                    //navigate("/");
                    store.dispatch(setVersionAction(version));
                }, 1000);
            },
            (err) => {
                if(err){
                    // cogoToast.error(
                    //     <CustomToast
                    //         heading={"Error"}
                    //         message={err[0]?.message || err.toString()}
                    //         type={"error"}
                    //     />,
                    // );
                }
            }
        );
        return () => {
        };
    }, [store?.getState()?.CommonReducer?.version]);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
          event.preventDefault();
          setInstallPromptEvent(event);
        };
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
        return () => {
          window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
      },[]);

    return (
        //scroll to top on route change
        <ScrollToTop>
            <Suspense
                fallback={
                    <div
                        style={{height: "100vh", width: "100%"}}
                        className={"d-flex justify-content-center align-items-center"}
                    >
                        <img src={LoadingAnimation} alt="loading"/>
                    </div>
                }
            >           
                        {  //scroll to top on click of arrow 
                        !isDesktopOrLaptop && pathname!=="/" && pathname!=="/booking" &&  pathname!=="/stops"&& 
                        pathname!=="/seat-arrangement" && pathname!=="/payment" && pathname!=="/booking-details" && pathname!=="/profile-detail" &&
                            <div onClick={scrollToTopArrow} className="scrollToTopArrow">
                                <img  src={ScrollArrow}/>
                            </div>
                        }
                <Routes>
                    <Route path={"/"} element={<Home/>}/>
                    <Route path={"/booking"} element={<Bookings/>}/>
                    <Route path={"/booking/:id"} element={<Booking2/>}/>
                    <Route
                        path={"/seat-arrangement"}
                        element={<SeatArrangementMobile/>}
                    />
                    <Route path={"/stops"} element={<BoardingAndDroppingMobile/>}/>
                    <Route path={"/booking-details"} element={<ReviewPaymentContextProvider><ReviewAndPayment/></ReviewPaymentContextProvider> }/>
                    <Route path={"/payment"} element={<PaymentMobile/>}/>
                    <Route path={"/confirmation/:id"} element={<Confirmation/>}/>
                    <Route path={"/confirmation"} element={<Confirmation/>}/>
                    
                    <Route path={"/payment-processing/:id"} element={<PaymentInProcess/>}/>
                    <Route path={"/payment-processing"} element={<PaymentInProcess/>}/>

                    <Route path={"/failure/:id"} element={<PaymentFailure/>}/>
                    <Route path={"/failure"} element={<PaymentFailure/>}/>
                    <Route path={"/error"} element={<PageNotFound/>}/>
                    {/* Blog  */}


                    <Route path={"/blog"} element={<Blog/>}/>
                    <Route path={"/blog/march-long-weekend-trip-ideas"} element={<MarchLongWeekend/>}/>
                    <Route path={"/blog/guide-to-celebrate-holi-in-mathura-vrindavan"} element={<HoliCelebrrationMathura/>}/>
                    <Route path={"/blog/solo-travel-destinations-for-women"} element={<SoloFemaleTravellers/>}/>
                    <Route path={"/blog/travel-places-to-visit-with-friends"} element={<BestPlaceToVisitWithFriends/>}/>
                    <Route path={"/blog/places-to-visit-in-summer"} element={<BestPlacesToVisitSummer/>}/>
                    <Route path={'/blog/best-places-to-visit-in-august'} element={<BestPlacesTotravelInAugust/>}/>
                    <Route path={'/blog/monsoon-tourist-places-in-india'} element={<TwentyPlacestoVisitinIndiaDuringMonsoon/>}/>
                    <Route path={"/blog/best-south-indian-tourist-places-to-visit"} element={<Best11PlaceinSouthIndia/>}/>
                    <Route path={"/blog/sightseeing-places-to-visit-in-haridwar"} element={<Best15PlacestoVisitInHaridwar/>}/>
                    <Route path={"/blog/one-day-trip-from-bangalore"} element={<BestPlacesTotravelfor1dayfromBlr/>}/>
                    <Route path={"/blog/famous-tourist-places-to-visit-in-agra"} element={<Best10TouristPlacesInAgra/>}/>
                    <Route path={"/blog/places-to-visit-in-ujjain"} element={<Guide4Ujjain/>}/>
                    <Route path={"/blog/weekend-getaways-from-bangalore"} element={<WeekendendGetAwaysfromBlr/>}/>
                    <Route path={"/blog/famous-places-to-visit-in-vijayawada"} element={<Best15PlacestoTravelFromVijayWada/>}/>
                    <Route path={"/blog/things-to-do-in-pondicherry"} element={<Best10placesinPondicherry/>}/>




                    <Route path={"/coupon-list"} element={<CouponList/>}/>
                    <Route path={"/change-booking"} element={<ChangeBookingPage/>}/>
                    <Route
                        path={"/reschedule/:id"}
                        element={<RescheduleConfirmation/>}
                    />
                    <Route path={"/reschedule"} element={<RescheduleConfirmation/>}/>
                    <Route path={"/offers"} element={<OfferListPageDesktop/>}/>
                    <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
                    <Route path={"/support"} element={<Support/>}/>
                    {/*<Route path={'/environment'} element={<PageEnvironment/>}/>*/}
                    <Route path={"/environment"} element={<EnvironmentPage/>}/>
                    <Route path={"/faq"} element={<Faq/>}/>
                    <Route path={"/terms-conditions"} element={<TermConditions />} />
                    <Route path={"/qr-code"} element={<QrPage/>}/>
                    <Route path={"/my-booking"} element={<MyBookings/>}/>
                    <Route
                        path={"/all-bus-destination"}
                        element={<AllBusDestination/>}
                    />
                    <Route path={"/all-routes"} element={<AllRoutes/>}/>
                    <Route path={"/all-routes/bus/:id"} element={<CoachDetailsNew child={installPromptEvent}/>}
                    />
                    {/*<Route path={"/about-us"} element={<AboutUs/>}/>*/}
                    <Route path={"/profile-detail"} element={<ProfileDetail/>}/>
                    <Route path={"/wallet"} element={<Wallet/>}/>
                    <Route path={"/lounge"} element={<Lounge/>}/>
                    <Route path={"/lucky-draw"} element={<LuckyDraw/>}/>
                    <Route path={"/recharge"} element={<AddMoneyResultPage/>}/>
                    <Route path={"/ticket-view"} element={<TicketDetailPage/>}/>
                    <Route path={"/careers"} element={<Careers/>}/>
                    <Route path={"/about-us"} element={<AboutUsPage/>}/>
                    <Route path={"/news"} element={<News/>}/>
                    {/* <Route path={"/newsroom"} element={<AllnewsComponent/>}/> */}
                    <Route path={"/partner-with-us"} element={<PartnerWithUs/>}/>
                    <Route path={"/completed-trip"} element={<CompletedTrip/>}/>
                    <Route path={"/faq/:id"} element={<FAQDetails/>}/>
                    <Route path={"/booking-state"} element={<BookingState/>}/>
                    <Route path={"/offer-available"} element={<OfferAvailable/>}/>
                    <Route path={"/change-booking-section"} element={<ChangeBookingSection/>}/>
                    <Route path={"/download-app"} element={<DownloadApp/>}/>
                    <Route path={"/fnb"} element={<Fnb/>}/>
                    <Route path={"/fnbstatus"} element={<FnBConfirmation/>}/>
                    <Route path={"/co-passengers"} element={<CoPassengerDetailPage/>}/>
                    <Route path={"/checklist"} element={<ChecklistPageMobile/>}/>
                    <Route path={"/checklist/:id"} element={<ChecklistDetailPageMobile/>}/>
                    <Route path={"/call"} element={<DesktopFAQForm/>}/>
                    <Route path={"/offers/:id"} element={<MobileOffer/>}/>
                    <Route path={"/nuego-safety"} element={<NuegoSafetyMobile/>}/>
                    <Route path={"/booking-failed"} element={<PaymentDeductedSeatNotBooked/>}/>
                    <Route path={"/booking-failed/:id"} element={<PaymentDeductedSeatNotBooked/>}/>
                    <Route path={"/guest-user"} element={<GuestUserBookingMobile/>}/>
                    <Route path={'/magazine'} element={<Magazine/>}/>
                    <Route path={"/magazine/:id"} element={<Itinerary/>}/>
                    <Route path={"/guest-user"} element={<GuestUserBookingMobile/>}/>
                    <Route path={"/stops"} element={<BoardingAndDroppingMobile/>}/>
                    <Route path={"/seat-arrangement"} element={<SeatArrangementMobile/>}/>
                    <Route path={"/checklist"} element={<ChecklistPageMobile/>}/>
                    <Route path={"/checklist/:id"} element={<ChecklistDetailPageMobile/>}/>
                    <Route path={'/sitemap'} element={<Sitemap/>} />
                    <Route path='*' element={<NoRoute404/>}/>
                    </Routes>
 
            </Suspense>
        </ScrollToTop>
    );
};

export default CombinedRoutes;
